

































import {
  AnyObject,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { ProductReviewData, Variant } from '../../../../contexts'

import { DrawerType, IDrawer } from '../../../../front/shared/contracts/services'
import { Inject, logger } from '../../../../support'
import { openAuthDrawer } from '../../../../front/auth/shared'
import {
  IProductReviewsRepository,
  ReviewsRepositoryType
} from '../../../../front/products/contracts/repositories'
import { Loader } from '../../../../front/shared/molecules/Loader'
import { Modals } from '../../../../front/products/config/modals'
import { ProductReview } from '../../../../front/products/molecules/ProductReview'
import { RateSummary } from '../../../../front/products/molecules/RateSummary'

import { ProductReviewsMixin } from '../../ProductReviews.mixin'
import {
  ITrustedShop,
  TrustedShopRepositoryType
} from '../../../../front/shared/repositories/trustedShop'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<Paginate>({
  name: 'Paginate',
  components: { Loader, ProductReview, RateSummary },
  created (): void {
    if (this.content.product) {
      this.fetchProductReviewsList()
    }
  }
})
export class Paginate extends ProductReviewsMixin {
  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(ReviewsRepositoryType, false)
  protected readonly reviewsRepository?: IProductReviewsRepository

  @Inject(TrustedShopRepositoryType)
  protected readonly trustedShopRepository?: ITrustedShop

  /**
   * Fetch product reviews list
   * @protected
   */
  protected async fetchProductReviewsList (): Promise<void> {
    if (!this.reviewsRepository || !this.activeVariant || !this.product) {
      return
    }

    const skus = this.variants.map((variant) => {
      return variant.sku
    })

    try {
      this.isLoading = true
      this.reviews = await this.reviewsRepository.loadProductReviews(skus)
    } catch (e) {
      logger((e as Error).message, 'error')
    }

    this.isLoading = false
  }

  /**
   * Determines product rating
   */
  public get rateSummary () {
    if (!this.reviews || !this.product) {
      return null
    }

    // FIXME -> waiting for Mikołaj endpoint
    let validatedReviewsAmount = 0
    const reviewMeanValue = this.variants.map(variant =>
      variant.rating?.average.rate ?? 0)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    const amountPerRate = [0, 0, 0, 0, 0]

    this.reviews.forEach((review: ProductReviewData) => {
      if (review && review.rate > 0 && review.rate < 6 && Number.isInteger(review.rate)) {
        amountPerRate[review.rate - 1]++
        validatedReviewsAmount++
      }
    })
    return {
      amountPerRate: amountPerRate.reverse(),
      average: {
        amount: validatedReviewsAmount,
        rate: reviewMeanValue
      },
      product: this.product
    }
  }

  /**
   * Reviews list per page
   */
  public get reviewsList () {
    if (!this.reviews || !this.reviews.length) {
      return []
    }

    const start = ((this.currentPage - 1) * this.reviewsPerPage)
    const end = start + this.reviewsPerPage

    return this.reviews.slice(start, end)
  }

  public get selectedVariant (): Variant<string> | undefined {
    if (!this.product || !this.$route.query.sku) {
      return undefined
    }

    return this.variants.find((product) => {
      return product.sku === this.$route.query.sku
    })
  }

  /**
   * Opens add review modal
   */
  public openAddReviewModal (): void {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    this.modalConnector.open(Modals.AddReviewModal, {
      sku: this.$route.query.sku ?? this.activeVariant?.sku,
      title: this.selectedVariant ? this.selectedVariant.name : this.activeVariant?.name,
      description: this.selectedVariant ? this.selectedVariant.description : this.activeVariant?.description,
      variantHex: this.selectedVariant ? this.selectedVariant.identifier.value
        : this.activeVariant?.identifier.type === 'color' ? this.activeVariant?.identifier.value : undefined
    })
  }

  public pickReviewByVariantSku (sku: string): AnyObject {
    const candidate = this.variants.find((variant) => {
      return variant.sku === sku
    })

    if (!candidate) {
      logger(`Could not find [${sku}] variant! `, 'warn')
    }

    return candidate as AnyObject
  }

  /**
   * Builds full url for product with given slug.
   */
  public buildFullProductLink (sku: string): string {
    return `${this.product?.urlPath}?sku=${sku}`
  }

  /**
   * Handle query change.
   */
  public updateQuery (newQuery: string): void {
    this.$router.push(newQuery)
  }
}

export default Paginate
